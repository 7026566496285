.register {
    position: relative;
    height: 100vh;
    background-image: url('../../src/images/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__form {
        margin: 0 auto;
        width: 500px;
        position: absolute;
        margin-top: 5px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        -moz-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        padding: 20px 50px;
        border-radius: 15px;
        background-color: #dce4ecee;

        &-input{
            margin-bottom: 10px !important;
            };

        &-title {
            text-align: center;
            padding-bottom: 12px;
            line-height: 0;
            font-size: 20px !important;
            text-transform: uppercase;
        }

        &-redirect {
            color: rgb(59, 56, 56);
            opacity: 0.7;
            margin: 20px auto 0;

            &:hover {
                opacity: 1;
            }
        }
            &-error {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    margin-left: 6px;
                    color: rgba(169, 2, 2, 0.839);
                    font-weight: 500;
                }

            }
    }

    &__loading{
        margin-top: 20px;
    }
}


    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
        margin: 6px 0 !important;
    }