.header {
    background-color: #DCE4EC;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    margin-bottom: 100px !important;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__bottom {
        margin-top: 25px;
        background-color: #DCE4EC;
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
    }

    &__rigth {
        display: flex;
        align-items: center;
    }

    &__lang {
        display: flex;
        align-content: center;
        align-items: center;
        font-weight: 300;
        cursor: pointer;

        &.active {
            font-weight: 700;
        }
    }

    &__logo {
        text-transform: uppercase;
        font-size: 22px;
        color: rgb(82, 82, 82)
    }
}

.home {
    &__menu {
        background-color: #b0c3d471;
        display: flex;
        justify-content: start;
        padding: 15px 50px;
        margin-top: 75px;

        &-link {
            font-size: 19px;
            color: rgb(75, 75, 75);
            margin-right: 35px;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.active {
    font-weight: 600;
}

.main_header {
    background-color: #DCE4EC;
}

.header-login {
    background-color: #DCE4EC;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}
