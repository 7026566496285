.report {
    padding: 0 50px;
    margin-top: 120px;
    height: 70vh;
}

.report__form {
    &-title {
        text-transform: uppercase;
        font-size: 32px !important;
        color: rgba(50, 85, 98, 0.823);
        font-weight: 300 !important;
    }

    &-header {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_rigth {
            padding: 20px 0;
            display: flex;
            justify-content: end;
            align-items: center;
        }
    }

    &-date {
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &-check {
        color: rgb(85, 85, 85);

        checkbox {
            margin: 10px;
        }
    }
}

.download-link {
    color: rgb(71, 71, 117);
    cursor: pointer;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
    }
}

.row {
    text-align: center !important;

    p {
        font-size: 20px;
        font-weight: 500;
    }
}


.datepicker {
    margin: 10px !important;
    display: none;
}

.row-flex {
    display: flex !important;
    justify-content: space-between !important;
    border: none !important;
}

.dock {
    color: rgb(71, 71, 117);

    &:hover {
        opacity: 0.6;
    }
}

.table-header {
    border-top: 1px solid #ccc;

    &:first-child {
        border-left: 1px solid #ccc;
    }
}

.table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 1px solid #ccc;

    &:first-child {
        border-left: 1px solid #ccc;
    }
}

.status{
    background-color: rgba(77, 171, 36, 0.218);
}

.table__height{
    height: 70vh;
}

.sort-button{
    border: none;
    margin-left: 7px;
    cursor: pointer;
    opacity: 0.4;

    &:hover{
    opacity: 0.9;
    }

    &.sort-reverse {
        transform: rotate(180deg);
    }
}