.track {
    margin-left: 20px;

    &__title {
        text-align: center;
        font-size: 22px;
        color: rgb(60, 66, 68);
    }

    &__form {
        display: flex;
        margin-top: 30px;
        align-items: center;

        input {
            border-radius: 0 !important;
        }

        button {
            width: 100px;
            height: 57px;
            border-radius: 0;
            border-left: none;
            border: transparent;
            background-color: #e0e9ea;
            color: rgb(106, 108, 109)
        }
    }

    &__data {
        margin: 20px 0;
        span {
            font-size: 17px;
            color: rgb(90, 90, 90)
        }

        &-loading {
            margin-top: 30px;
        }

        &-list{
            list-style: none;
    
            li{
                margin-bottom: 5px;
                color:rgb(32, 38, 40)
            }
        }
    }
}

.box {
    position: absolute;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
};

.downloadLink{
color: #567ca6;
font-weight: 500;

&:hover{
color: #3b608b !important;
}
};

.downloadLink-disabled{
      pointer-events: none;
        color: rgb(98, 98, 98);
  }

.download_info{
    color: #3e4955;
    text-align: center;
    margin-top: 20px;
    display: flex;
    background-color: #bdc4c5a7;
    padding: 10px;
}