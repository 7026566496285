.restore {
      position: relative;
      height: 100vh;
      background-image: url('../../src/images/bg.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    &__form {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        -moz-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        padding: 50px;
        border-radius: 15px;
          background-color: #dce4ecee;
          &-redirect {
              color: rgb(59, 56, 56);
              opacity: 0.7;
              margin: 20px auto 0;
              &:hover {
                  opacity: 1;
              }
          }
          &-title {
              text-align: center;
              padding-bottom: 20px;
              font-size: 30px;
              text-transform: uppercase;
          }
    }
}
