* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a,
li,
ul,
ol {
    text-decoration: none;
    color: inherit;
    padding: 0;
    margin: 0;
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.app {
    position: relative;
}

.btn {
    display: flex;
    text-transform: uppercase;
    padding: 10px 20px;
    line-height: 1.2;
    border: 2px solid rgb(149, 149, 149);
    flex-wrap: nowrap;

    &-sm {
        width: 200px;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        height: 41px;
        font-weight: 500;
        color: rgb(43, 117, 201);
        background-color: transparent;
        border: 2px solid rgb(149, 149, 149);
        line-height: 1.5;
        margin-left: 10px;
    }

}


::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #d9dddd;
}

::-webkit-scrollbar-thumb {
    background-color: #bababa;
}

::-webkit-scrollbar-button:horizontal:end:increment {
    background:
        linear-gradient(210deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(330deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(90deg, #bababa 30%, rgba(0, 0, 0, 0) 31%);
    background-color: #f6f8f4;
}

::-webkit-scrollbar-button:vertical:start:decrement {
    background: linear-gradient(120deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(240deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(0deg, #bababa 30%, rgba(0, 0, 0, 0) 31%);
    background-color: #f6f8f4;
}

::-webkit-scrollbar-button:vertical:end:increment {
    background:
        linear-gradient(300deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(60deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(180deg, #bababa 30%, rgba(0, 0, 0, 0) 31%);
    background-color: #f6f8f4;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
    background:
        linear-gradient(30deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(150deg, #bababa 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(270deg, #bababa 30%, rgba(0, 0, 0, 0) 31%);
    background-color: #f6f8f4;
}