.login {
    position: relative;
    height: 100vh;
    background-image: url('../../src/images/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &-success{
        width: 300px;
          position: absolute;
          top: 50;
          right: 0;
    }

    &-alert {
        max-width: 700px;
       display: flex;
       justify-content: center;
       margin: 0 auto;
        z-index: 1000;
    }

    &__form {
        width: 500px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        -moz-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.1);
        padding: 50px;
        border-radius: 15px;
        background-color: #dce4ecee;

        &-input {
            background-color: transparent !important;
        }

        &-password{
            background-color: #ccc !important;

        }

        &-title {
            text-align: center;
            padding-bottom: 20px;
            color: rgb(59, 56, 56);
            text-transform: uppercase;
        }

        &-redirect {
            color: rgb(59, 56, 56);
            margin-top: 20px;
            opacity: 0.7;
            margin: 20px auto 0;

            &:hover {
                opacity: 1;
            }
        }

        &-error {
            margin-top: 20px;
            text-align: center;
            display: flex;
            align-items: center;

            span {
                margin-left: 6px;
                color: rgba(169, 2, 2, 0.839);
                font-weight: 500;
            }

        }
    }
}

 .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
     margin: 15px 0 5px 0;
 }