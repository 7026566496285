.footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    background-color: #DCE4EC;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
